import React, { useEffect, useState, useRef, Fragment } from "react";
import {useScrollDirection} from "./use-scroll"

export default function HeaderNav() {  
  return (
    <div className="navigation---background wf-section">
        <div className="standard-navigation">
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-logo-left-container shadow-three w-nav">
                <div className="navigation-container">
                    <div className="navigation-wrapper"><a href="https://www.nerdcollective.xyz/" className="navigation-logo w-nav-brand" aria-label="home"><img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/6358ca983ea08a254b4414ea_logo%20white%20stacked%20naked%20small%20-%20on%20black.png" loading="lazy" height="40" alt="" className="image-13" /></a>
                    <nav role="navigation" className="nav-menu-wrapper-2 w-nav-menu">
                        <ul role="list" className="navigation-menu w-list-unstyled">
                        <li className="nav-item-container"><a href="https://www.nerdcollective.xyz/our-moment" aria-current="page" className="nav-item w--current">OUR&nbsp;MOMENT</a></li>
                        <li className="nav-item-container"><a href="https://www.nerdcollective.xyz/faq" className="nav-item">FAQ</a></li>
                        <li className="list-item-4">
                        <a href="https://www.nerdcollective.xyz/founders-token" className="nav-item">FOUNDERS&nbsp;TOKEN</a>
                        </li>
                        <li className="nav-item-container icon">
                            <div className="div-block-31"><a href="https://twitter.com/NerdCollective_" target="_blank" className="navigation-social-media-icon w-inline-block"><img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/634627ab5eeb58f58239b9ff_Twitter%20icon.png" width="40" height="40" alt="" /></a><a href="https://discord.gg/thenerdcollective" target="_blank" className="navigation-social-media-icon right w-inline-block"><img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/634627b53b05aa26207daeda_Discord%20Icon.png" loading="lazy" width="40" height="40" alt="" /></a></div>
                        </li>
                        </ul>
                    </nav>
                    <div className="menu-button-2 w-nav-button" aria-label="menu" role="button"  aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false"><img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/632b9acc3308144ef41abfae_hamburger%20menu.png" width="32" height="28" alt="" /></div>
                    </div>
                </div>
                <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
            </div>
        </div>
    </div>
)}
