import React, { useEffect, useState, useRef, Fragment } from "react";
import Minter from "./minter";

export default function MintArea() {

  return (
    <div className="hero-splash w-container">
      <div className="hero-content-founders-token">
        <div className="columns-3 w-row">
          <div className="column-6 w-col w-col-7 w-col-stack">
            <h1 className="h1 white csschange">THE NERD COLLECTIVE
            <span className="hero-subtitle">GENESIS PFP</span>
            </h1>
            <p className="hero-body-text">Our Genesis PFP is a digital collectible. A stunning hand-drawn rendering of a "Nerd". Each Nerd NFT is unique and randomly generated drawing from over 250 traits. To start, having a nerd gives you access to:<br /></p>
            <ul role="list" className="list">
              <li className="hero-body-text">• Gated content, giveaways and a treasure hunt</li>
              <li className="hero-body-text">• Exclusive nerd-centric channels in our discord</li>
              <li className="hero-body-text">• Access to The Nerd Collective App</li>
            </ul>
            <div className="div-block-25">
              <a href="https://nerdcollective.webflow.io/genesis-pfp" className="link-block-2">
                <p className="text-link">Learn More<br /></p><img className="carrot-image" src="../assets/images/cta-down-carrot.png" loading="lazy" width="13" height="7" alt="" />
              </a>
            </div>
          </div>
          <div className="column-7 w-col w-col-5 w-col-stack">
              <Minter />
          </div>
        </div>
        
        
      </div>
      <div className="hero-image-hawk"></div>
    </div>

  )
};

