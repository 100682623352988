import React, { useEffect, useState, useRef, Fragment } from "react";

export default function TNCFooter() {

  return (
    <div className="footer wf-section">
      <div className="footer-container">
        <div className="div-block-33">
          <a href="https://www.nerdcollective.xyz/" aria-current="page" className="w-inline-block w--current">
            <img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/6358ca983ea08a254b4414ea_logo%20white%20stacked%20naked%20small%20-%20on%20black.png" loading="lazy" width="Auto" height="60" alt="" />
              </a>
        </div>
        <div className="div-block-32">
          <div className="columns-6 w-row">
            <div className="column-14 w-col w-col-6">
              <div className="div-block-35">
                <a href="https://www.nerdcollective.xyz/our-moment" className="footer-link w-inline-block">
                    <div className="nav-item---footer">OUR&nbsp;STORY</div>
                </a>
                <a href="https://www.nerdcollective.xyz/the-team" className="footer-link w-inline-block">
                <div className="nav-item---footer">THE&nbsp;TEAM</div>
               </a>
               <a href="https://www.nerdcollective.xyz/faq" className="footer-link w-inline-block">
                <div className="nav-item---footer">FAQ</div>
              </a>
              <a href="https://www.nerdcollective.xyz/founders-token" className="footer-link w-inline-block">
                <div className="nav-item---footer">FOUNDERS&nbsp;TOKEN</div>
              </a>
              <a href="https://www.nerdcollective.xyz/our-art" className="footer-link w-inline-block">
                <div className="nav-item---footer">OUR&nbsp;ART</div>
              </a>
              <a href="https://www.nerdcollective.xyz/nerdish" className="footer-link w-inline-block">
                <div className="nav-item---footer">NERDISH</div>
              </a>
            </div>
          </div>
          <div className="column-15 w-col w-col-6">
            <div>
              <div className="nav-item---footer right-footer">FOLLOW&nbsp;US</div>
              <div className="div-block-34"><a href="https://twitter.com/NerdCollective_" target="_blank" className="w-inline-block"><img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/634627ab5eeb58f58239b9ff_Twitter%20icon.png" loading="lazy" width="40" alt="" className="social-media-icon" /></a><a href="https://discord.gg/thenerdcollective" target="_blank" className="social-icon---footer w-inline-block"><img src="https://uploads-ssl.webflow.com/63225d4c303956d6093042f9/634627b53b05aa26207daeda_Discord%20Icon.png" loading="lazy" width="40" alt="" className="social-media-icon" /></a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-text"><a href="https://www.nerdcollective.xyz/terms-conditions" target="_blank" className="link-3 t-and-c-link">TERMS &amp;&nbsp;CONDITIONS</a> | © 2022 THE NERD COLLECTIVE LLC</div>
    </div>

   
  </div>
  )
}
