import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// navigation attemp
export const Navigation = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: row;
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 9999;
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (min-width: 767px) {
    width: 80%;
  }
`;

export const StyledOption = styled.option`
  // color: ${(props) => (props.selected ? "lightgrey" : "black")};
`;

export const Herotiny = styled.div`
  position: absolute;
  width: 71%;
  bottom: 16%;
  left: 36%;
  font-size: 17px;
  color: #fff;
`;

export const HeroeD = styled.div`
  position: absolute;
  font-size: 17px;
  width: 100%;
  text-align: center;
  padding: 0 4%;
  top: 55%;
  font-size: 17px;
  text-align: center;
  padding: 6px 4%;
  color: #FFA500;
  width: 100%;
  border-radius: 24px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  @media (min-width: 767px) {
    top: 25%;
    font-size: 18px;
    display: block;
    width: 58%;
    right: 1%;
  }
`;

export const Herotext = styled.div`
  position: absolute;
  line-height: 34px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 17px;
  text-align: center;
  line-height: 21px;
  width: 93%;
  top: 25%;
  left: 50%;
  @media (min-width: 767px) {
    font-size: 26px;
    line-height: 32px;
    width: 50%;
    top: 20%;
    left: 70%;
  }
`;

export const MintHeroeImg = styled.div`

  background: #000 url(/config/images/hmvtoken.jpg) no-repeat 11% 32%;
  background-size: 100%;
  background-repeat: no-repeat;
  display: none;
  background-position: left;
  position: relative;
  border-radius: 50%;
  min-height: 430px;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  flex: 1.5;
  border: 4px solid var(--secondary);
  background-color: #000;
  // height: 434px;
  min-width: 430px !important;
  @media (min-width: 767px) {
    min-width: 600px;
    display: block;
  }
`;

export const StyledBannerImg = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url("/config/images/webbanner2.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 24px;
  
  box-shadow: rgb(0 0 0 / 70%) 0px 5px 11px 2px;
  width: 90%;
  line-height: 20px;
  height: 294px;
  @media (min-width: 767px) {
    width: 94%;
    line-height: 26px;
    margin-bottom: 15px;
    height: 86vh;
    background-position: top;
  }
`;

export const Faqcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 40px;
  @media (min-width: 767px) {
    width: 70%;
  }
  p {
    padding: 10px 0;
    line-height: 19px;
  }
`;


export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (min-width: 767px) {
    width: 60%;
  }
`;

export const Labelspan = styled.div`
  flex: .5;
  display: flex;
  
  align-items: center;
  padding-right: 10px;
  justify-content: left;
  white-space: nowrap;
  @media (min-width: 767px) {
    justify-content: right;
  }

  
`;

export const Bio = styled.div`
line-height: 26px;
text-align: left;
padding: 23px 0;
margin: 0px 8%;
`;


export const BookChecck = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  padding-top: 14px;
`;
export const Pusher = styled.div`
  flex: 1;
`;

export const Navllist = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const Menulink = styled.a`
  color: white;
  text-align: center;
  text-decoration: none;
`;

export const Navitem = styled.li`
  float: left;
  padding-right: 17px;
  font-size: 14px;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;
export const Copywrite = styled.div`
  font-size: 11px;
  padding-left: 19px;
  flex: 1;
`;

export const FootLinks = styled.div`
  font-size: 17px;
  padding-right: 20px;
`;


export const Footer = styled.div`
  width: 100%;
  padding-top: 10px;
  background-color: white;
  border-top: 1px solid var(--primary-text);
  height: 48px;
  background-color: var(--accent);
  display: flex;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  position: fixed;
  background-color:rgba(6, 6, 6, 0.8);
  z-index: 99999998;
  position: fixed;
`;

export const Loader = styled.div`
  border: 11px solid #efe7b4; /* Light grey */
  border-top: 11px solid #ffc62e; /* Blue */
  border-radius: 50%;
  width: 180px;
  height: 180px;
  animation: spin 2s linear infinite;
  z-index: 99999999;
  position: absolute;
  top: 41%;
  left: 45%;
`;


export const Burntotal = styled.div`
  position: absolute;
  bottom: 4px;
  font-size: 18px;
  right: auto;
  left: 11px;
  color:  var(--third) !important;
  @media (min-width: 767px) {
    right: 15px;
    left: auto;
  }
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  // line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
